.context-toolbar {
  .top-toolbar {
    padding-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
  }

  .bottom-toolbar {
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .bottom-toolbar.with-keyboard {
    bottom: 350px;
    transition: bottom 1s;
  }

  .bottom-toolbar,
  .top-toolbar {
    display: flex;
    flex-direction: row;

    .button {
      margin-right: 20px;
    }

    .button:last-child {
      margin-right: 0;
    }

    .left-side {
      display: flex;
      flex-direction: row;
    }

    .center {
      display: flex;
      flex-direction: row;
    }

    .right-side {
      display: flex;
      flex-direction: row;
    }
  }

  @import "./partials/TextToolbar/TextToolbar.scss";
  @import "./partials/TagToolbar/TagToolbar.scss";
  @import "./partials/DrawToolbar/DrawToolbar.scss";
  @import "./partials/ManipulationToolbar/ManipulationToolbar.scss";
}
