.progress {
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .progress-container {
    max-width: 80%;
    width: 300px;
    border: solid 1px white;
    height: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .progress-content {
      height: 100%;
      width: 0%;
      background-color: white;
      transition: width 0.1s;
    }
  }
}
