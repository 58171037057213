.main-toolbar {
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 10px;
}
