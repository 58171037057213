.draw-toolbar {
  background-color: red;
}

.undo-draw {
  position: absolute;
  left: 10px;
  top: 60px;
}

.stroke-width-container {
  position: absolute;
  width: 40px;
  left: 15px;
  bottom: 200px;

  .stroke-width-wrapper {
    position: relative;
  }

  .rangeslider__labels {
    display: none;
  }

  .rangeslider__handle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }

  .stroke-width-container {
    position: absolute;
  }

  .stroke-width-background {
    padding-top: 9px;
    img {
      width: 25px;
      height: auto;
    }
  }

  .slider-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .rangeslider-vertical {
      margin: 0 9px;
      height: 100%;
      box-shadow: none;
      .rangeslider__fill {
        opacity: 0;
      }
    }
  }
}
