@import "./common//reset";
@import "./common/devices";
@import "./common/fonts";

@import "../components//Icon/Icon.scss";
@import "../components/MainToolbar/MainToolbar.scss";
@import "../components/ContextToolbar/ContextToolbar.scss";
@import "../components/ColorSwiper/ColorSwiper.scss";
@import "../components/IconButton/IconButton.scss";
@import "../components/ContextToolbar/partials/CropToolbar/CropToolbar.scss";

@import "../pages/EditorPage/EditorPage.scss";

html {
  width: 100%;
  height: 100%;
  //overflow: auto;
  background-color: black;
}

body {
  width: 100%;
  height: 100%;
  //overflow: auto;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: black;
}

#canvas {
  margin-left: auto;
  margin-right: auto;
}

.emptyPage {
  height: 100vh;
  width: 100vw;
  background-color: black;
}
