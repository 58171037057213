.text-toolbar {
  .hidden-form {
    opacity: 0;
    position: absolute;
    top: -500px;
    left: -500px;
  }
  .hidden-field {
    //user-select: none;
  }
}

.delete-text {
  position: absolute;
  left: 10px;
  top: 60px;
}
