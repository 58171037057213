.crop-container {
  position: absolute;
  top: 80px;
  left: 30px;
  width: 150px;
  height: 200px;

  $cornerSize: 30px;

  .crop-content {
    position: relative;
    height: 100%;
    width: 100%;
    border: solid 1px yellow;
  }

  .side {
    position: absolute;
    //border: solid 1px red;
    box-sizing: border-box;

    &.top {
      height: $cornerSize;
      left: $cornerSize/2;
      right: $cornerSize/2;
      top: $cornerSize/-2;
    }
    &.right {
      width: $cornerSize;
      top: $cornerSize/2;
      bottom: $cornerSize/2;
      right: $cornerSize/-2;
    }
    &.bottom {
      height: $cornerSize;
      left: $cornerSize/2;
      right: $cornerSize/2;
      bottom: $cornerSize/-2;
    }
    &.left {
      width: $cornerSize;
      top: $cornerSize/2;
      bottom: $cornerSize/2;
      left: $cornerSize/-2;
    }
  }

  .corner {
    position: absolute;
    width: $cornerSize;
    height: $cornerSize;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border: solid 1px yellow;
      background-color: yellow;
      box-sizing: border-box;
    }

    &.top {
      top: $cornerSize/-2;
    }

    &.left {
      left: $cornerSize/-2;
    }

    &.right {
      right: $cornerSize/-2;
    }

    &.bottom {
      bottom: $cornerSize/-2;
    }
  }
}
