.style-list {
  border-radius: 5px;
  padding: 5px;
  border: solid 1px white;
  color: white;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.tag-hidden-field {
  opacity: 0;
  position: absolute;
  // user-select: none;
  top: -500px;
  left: -500px;
}
